import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './store';

import './App.css';
import Navbar from './components/Navbar';
import Headers from './components/Headers';
import Homepage from './pages/Homepage';
import About from './pages/About';
import ContactUs from './pages/ContactUs';

import SingleService from './pages/elements/SingleService';
import Privacy from './pages/Privacy';
import CancellationPolicy from './pages/CancellationPolicy';
import Terms from './pages/Terms';
import RefundPolicy from './pages/RefundPolicy';
import CloudComputing from './pages/CloudComputing';
import NetworkSecurity from './pages/NetworkSecurity';
import ITES from './pages/ITES';
import Cart from './pages/Cart';
import User from './pages/Auth/User';
import Checkout from './pages/Checkout';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <div className='wrapper d-flex align-items-stretch'>
            <Navbar />

            {/* Page Content  */}
            <div id='content'>
              <Headers />

              <Route exact path='/' component={Homepage} />
              <Route exact path='/about-us' component={About} />
              <Route exact path='/checkout' component={Checkout} />
              <Route exact path='/contact-us' component={ContactUs} />
              <Route exact path='/privacy-policy' component={Privacy} />
              <Route
                exact
                path='/cancellation-policy'
                component={CancellationPolicy}
              />
              <Route exact path='/terms-of-service' component={Terms} />
              <Route exact path='/refund-policy' component={RefundPolicy} />
              <Route exact path='/cloud-computing' component={CloudComputing} />
              <Route
                exact
                path='/network-security'
                component={NetworkSecurity}
              />
              <Route exact path='/managed-services' component={ITES} />
              <Route exact path='/cart' component={Cart} />
              <Route exact path='/user' component={User} />

              <Route
                exact
                path='/services/:service'
                component={SingleService}
              />
            </div>
          </div>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
