import React, { Component } from 'react';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { CartComponent } from '../components/CartComponent';
import { CheckoutComponent } from '../components/CheckoutComponent';
import { CartTotalComponent } from '../components/CartTotalComponent';

class Checkout extends Component {
  render() {
    return (
      <div style={{ background: '#f1f1f8' }}>
        <div className='p-3 '>
          <h1> Checkout </h1>
          <div className='row'>
            <div className='col-md-9'>
              <CheckoutComponent />
            </div>
            <div className='col-md-3'>
              <CartTotalComponent />
              <div
                style={{
                  background: '#fff',
                  padding: '20px',
                  marginTop: '10px',
                }}
              >
                <img
                  src='/images/payments.jpg'
                  alt='Payment'
                  style={{ height: '75px' }}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Checkout;
