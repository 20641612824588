import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';

export class NetworkSecurity extends Component {
  render() {
    return (
      <div>
        <PageBanner title="Network Security" background="page-banner.jpg" />
        <div className="p-3">
          <p>
            Network security is any action that is purposely designed to
            safeguard the serviceability and integrity of your network and data.
            It comprises both hardware and software technologies. Effective
            network security controls access to the network. It aims a variety
            of threats and terminates them from entering or spreading on your
            network.
          </p>
          <p>
            In other terms, Network security is the security offered to a
            network from unauthorized access and risks. It is the duty of
            network administrators to embrace preventive measures to safeguard
            their networks from potential security threats. The most common and
            simple way of safeguarding a network resource is by assigning it a
            distinctive name and a corresponding password.
          </p>
          <div className="row">
            <div className="col-md-6">
              <h5> How does network security work?</h5>
              <p>
                {' '}
                Network security integrates several layers of defences at the
                edge and in the network. Each network security layer executes
                policies and controls. Authorized users obtain access to network
                resources, but harmful actors are blocked from implementing
                exploits and threats.
              </p>
            </div>
            <div className="col-md-6">
              <h5> How do I benefit from network security?</h5>
              <p>
                Digitization has modified our lives from the way how we live,
                work, and play, to learn everything have changed. Every
                organization that wants to deliver the services that customers
                and employees demand must safeguard its network. Network
                security also helps you safeguard proprietary information from
                attack. Basically, it protects your reputation.
              </p>
            </div>
          </div>

          <h5>Types of network security </h5>
          <div className="row">
            <div className="col-md-4">
              <h5>FIREWALLS </h5>
              <p>
                {' '}
                Firewalls put up an obstacle between your trusted internal
                network and untrusted outside networks, such as the Internet.
                They use a set of clarified rules to permit or block traffic. It
                can be hardware, software, or both. IT Fixed 247 offers unified
                threat management (UTM) devices and threat-focused
                next-generation firewalls.
              </p>
            </div>
            <div className="col-md-4">
              <h5> EMAIL SECURITY</h5>
              <p>
                {' '}
                Email gateways are the primary threat vector for a security
                breach. Attackers utilize personal information and social
                engineering tactics to create sophisticated phishing campaigns
                to trick recipients and send them to sites serving up malware.
                An email security application blocks incoming attacks and
                manages outbound messages to prohibit the loss of sensitive
                data.
              </p>
            </div>
            <div className="col-md-4">
              <h5>NETWORK SEGMENTATION</h5>
              <p>
                Software-defined segmentation sets network traffic into various
                classifications and makes enforcing security policies simple.
                Theoretically, the categorization are based on endpoint
                identity, not mere IP addresses. You can allocate access rights
                based on role, location, and more so that the right level of
                access is given to the right people and sceptical devices are
                contained and remediated.
              </p>
            </div>
            <div className="col-md-4">
              <h5>APPLICTAION SECURITY</h5>
              <p>
                Any software you use to run your business requires to be
                safeguarded, whether your IT staff assembles it or whether you
                buy it. Unfortunately, any application may incorporate holes, or
                vulnerabilities, that attackers can utilize to infiltrate your
                network. Application security encloses the hardware, software,
                and processes you use to close those holes.
              </p>
            </div>
            <div className="col-md-4">
              <h5>ACCESS CONTROL</h5>
              <p>
                Not every user should have access to your network. To keep out
                prospective attackers, you need to understand each user and each
                device. Then you can impose your security policies. You can
                block noncompliant endpoint devices or give them only limited
                access. This procedure is network access control (NAC).
              </p>
            </div>
            <div className="col-md-4">
              <h5>DATA LOSS PREVENTION</h5>
              <p>
                Organizations must ensure that their staff does not send
                sensitive information outside the network. Data loss prevention,
                or DLP, technologies can prevent people from uploading,
                forwarding, or even printing critical information in an unsafe
                manner.
              </p>
            </div>
          </div>

          <h5>CONCLUSION</h5>
          <p>
            Network security is the practice of preventing and safeguarding
            against unauthorized intrusion into corporate networks. As a
            philosophy, it complements endpoint security, which concentrates on
            individual devices; network security instead concentrates on how
            those devices interact/get together and on the connective tissue
            between them.{' '}
          </p>
          <p>
            Therefore, it is the process of taking physical and software
            preventative measures to safeguard the underlying networking
            infrastructure from unauthorized access, misuse, malfunction,
            modification, destruction, or improper disclosure, thereby creating
            a secure platform for computers, users, and programs to carry out
            their permitted critical functions within a secure environment.
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center mb-2">
                <h2>Our Pricing</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card-deck mb-3 text-center">
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">Gold</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">$199</h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>10 users included</li>
                      <li>2 GB of storage</li>
                      <li>Email support</li>
                      <li>Help center access</li>
                    </ul>
                    <a
                      href="/contact-us"
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">Platinum</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">$299</h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>20 users included</li>
                      <li>10 GB of storage</li>
                      <li>Priority email support</li>
                      <li>Help center access</li>
                    </ul>
                    <a
                      href="/contact-us"
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">Custom</h4>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title pricing-card-title">
                      Contact Us for Price
                    </h3>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>Unlimited users included</li>
                      <li>Unlimited storage</li>
                      <li>Phone and email support</li>
                      <li>Help center access</li>
                    </ul>
                    <a
                      href="/contact-us"
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NetworkSecurity;
