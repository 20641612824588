import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';

export class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <PageBanner title='About Us' background='page-banner.jpg' />
        <div className='p-3'>
          <h3> About Us </h3>
          <p>
            ITSquad247 are the specialists you need to take care of your PC and
            connected devices. We are a team of highly skilled professionals
            whom you can rely upon when the gadgets around you give you any
            software-related hassle. Our experts take pride in bringing delight
            to our clientele via artless yet proficient software services.
            ITSquad247 deals in resolution of all issues affecting the
            performance of your devices. Our professionals provide services for
            a large variety of third-party brands and products including
            operating systems, browsers, security software, productivity
            applications and utilities.
          </p>
          <p>
            We also mend connectivity issues with various peripherals, devices
            and networking equipment.
          </p>
          <p>
            {' '}
            ITSquad247 strives to provide services to a large consumer base
            comprising home-based PC users and small offices. Our services can
            be availed round-the-clock, because we know that problems can happen
            at any time and a PC in distress is a big problem to our technology
            dependent customers.Our Morals: To deliver candid, refined and
            proficient technology services to our customers. To be transparent
            in what we deliver and strive towards contentment of our customers
            Our Motto: To be right behind our clients facing any
            software-related quandary with the devices they use.
          </p>
          <p>
            Our Objective: To establish ourselves as a brand that is a master of
            technology services. To achieve this goal, we always make efforts to
            achieve excellence while serving our customers:
          </p>
        </div>

        <Footer />
      </div>
    );
  }
}

export default About;
