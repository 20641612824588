import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';
class Privacy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <PageBanner title='Privacy Policy' background='page-banner.jpg' />
        <div className='p-3'>
          <p>
            ITFixed247 Privacy Policy ITFixed247, ("ITFixed247", "our", "we",
            us) offers a Privacy Policy dedicated to safeguarding your
            information from misuse. Our Privacy Policy is in effect during the
            period for which it is posted. New Privacy Policies will apply to
            subsequent uses of ITFixed247's services, with proper notification
            sent to users of ITFixed247.
          </p>
          <p>
            Use of ITFixed247 by creating an account is consent to this Privacy
            Policy. Our data is stored on servers in the United States and your
            agreement to this Privacy Policy is an understanding that your
            information may transfer to the US.
          </p>
          <h3> USE OF INFORMATION</h3>
          <p>
            Use and Gathering of Information and Data: Visiting ITFixed247
            triggers record keeping of your activities on the Website. Account
            creation requires information that makes you identifiable to us.
            This data results from monitoring the Website. Such data collection
            makes our information delivery efforts more effective. We reserve
            the right to contact you about ITFixed247. If you feel a certain
            type of communication from ITFixed247 is no longer suitable, you
            will have an option to unsubscribe via an email link or by
            contacting unsubscribe@ITFixed247.com.
          </p>
          <p>
            Registration Information Provision: Registering on ITFixed247 as
            either a "Client" or "Tech" entitles the user to greater access and
            use of ITFixed247. Accounts are conditional upon providing required
            data as mentioned in the respective sign up processes. ITFixed247
            will not sell this information.
          </p>
          <p>
            Tech Profiles: Techs have additional opportunities to provide
            supplemental information that enhances Clients' understanding of
            their skills and services. We reserve the right to modify
            information presented that does not comply with our "Terms and
            Conditions." We reserve the right to keep a record of this
            information. ITFixed247 will not sell this information.
          </p>
          <p>
            Feedback: ITFixed247 may on occasion ask its Users how it is
            performing, or their interest on service expansion. We reserve a
            right to contact Users of ITFixed247 with information provided to
            ITFixed247.
          </p>
          <h3> TRACKABLE INFORMATION</h3>
          <p>
            Usage Patterns: ITFixed247 monitors use of its website as a whole.
            Metrics include browsers, operating system, ISP, leading URLs, and
            your geographic location. We do not use this data to identify you
            and these metrics enhance understanding usage patterns on ITFixed247
            and provide a geographically relevant experience.
          </p>
          <p>
            Storable Cookies: ITFixed247 collects information from cookies.
            Cookies are small files stored on your computer granted to each
            user. These are snapshots of information such as preferences.
            Cookies are typically used with most websites to remember user
            preferences or track use of a website.
          </p>
          <p>
            Minors: We do no collect personally identifiable information of
            those under 18 years of age. If someone under 18 has set up an
            account at ITFixed247, please provide information to
            support@ITFixed247.com.
          </p>
          <h3>HOW WE SHARE INFORMATION</h3>
          <p>
            {' '}
            Client to Tech: We provide contact information of Clients with
            problems, to Techs. Clients reserve the right to specify their
            method of contact, and this will not include a phone number or
            physical address unless specified by the Client. Data does not
            transfer to Techs unless specified by Clients. As specified,
            information you submit in relation to a tech-problem transfers to
            Techs so they may bid on your problem. There will be instances where
            you have specified that only certain Techs have access to this
            information, and only those Techs will receive your tech-problem
            information for bidding.
          </p>
          <p>
            Tech to Client: Techs share information posted in their public
            profile. All information furnished here by the Tech reflects
            acceptance of sharing this information with ITFixed247's users.
          </p>
          <p>
            Sharing our Information: Financial, hosting, and tracking
            information generated by ITFixed247 is stored by third-party
            services, such as Amazon, Google, and PayPal. We share your
            information to the extent to make their services possible, and do
            not allow use for marketing or as a sale of this information to
            these parties.
          </p>
          <p>
            Law Enforcement: ITFixed247 complies with law enforcement and legal
            entities with jurisdiction over our activities. We reserve
            discretion to share your information with appropriate legal
            authorities as legally authorized by those authorities.
          </p>
          <p>
            Business Control: You will have the opportunity to remove yourself
            from any exchange between ITFixed247 and a resulting controlling
            entity if a new Privacy Policy differs from ITFixed247's policy. We
            reserve the right to share information with companies controlled by
            ITFixed247.
          </p>
          <p>
            Interfacing with Third-Party Sites: ITFixed247 integrates with
            social media to provide a way to advertise that you need a service
            on ITFixed247 or that you are providing a service on ITFixed247. You
            have control over this information. ITFixed247's default is to
            assume you wish to share this information, but you can opt-out
            before sharing information to a third-party site with which you have
            an account.
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Privacy;
