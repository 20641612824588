import { useEffect, useState } from 'react';

export const useCart = () => {
  const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    const cartData = window.localStorage.getItem('cart');
    if (cartData) {
      const cart = JSON.parse(cartData);
      // if product already exists in cart, increase quantity

      cart.push(product);
      window.localStorage.setItem('cart', JSON.stringify(cart));
      setCart(cart);
    } else {
      window.localStorage.setItem('cart', JSON.stringify([product]));
      setCart([product]);
    }
  };
  const checkItemInCart = (id) => {
    const cartData = window.localStorage.getItem('cart');
    if (cartData) {
      const cart = JSON.parse(cartData);
      const found = cart.find((item) => item.id === id);
      if (found) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const cartData = window.localStorage.getItem('cart');
    if (cartData) {
      setCart(JSON.parse(cartData));
    }
  }, []);

  const getCart = () => {
    const cartData = window.localStorage.getItem('cart');
    if (cartData) {
      return JSON.parse(cartData);
    }
    return [];
  };

  const clearCart = () => {
    window.localStorage.removeItem('cart');
    setCart([]);
  };

  const removeItem = (id) => {
    const cartData = window.localStorage.getItem('cart');
    if (cartData) {
      const cart = JSON.parse(cartData);
      const newCart = cart.filter((item) => item.id !== id);
      window.localStorage.setItem('cart', JSON.stringify(newCart));
      setCart(newCart);
    }
  };

  return {
    cart,
    addToCart,
    getCart,
    clearCart,
    removeItem,
    checkItemInCart,
  };
};
