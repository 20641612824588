import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

export default class Headers extends Component {
  render() {
    return (
      <Fragment>
        <div
          style={{
            background: '#fff',
            right: 0,
            width: '100%',

            zIndex: -1,
            borderBottom: '1px solid #EDF1F7',
            height: '50px',
          }}
        >
          <ul className='topheadermenu '>
            <li>
              <Link to='/cart'>
                <i className='fa fa-shopping-basket'> </i>
              </Link>
            </li>
            <li>
              <Link to='/user'>
                <i className='fa fa-user'> </i>
              </Link>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}
