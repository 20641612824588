import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';

export class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <PageBanner title='Contact Us' background='page-banner.jpg' />
        <div className='p-3'>
          <div className='row'>
            <div className='col-lg-12 clear no-padding'>
              <div className='row'>
                <div className='form col-md-7 col-sm-6 no-padding'>
                  <div className='card-box'>
                    <h2 className='text-center nomargin'>
                      We’d Love to Hear from You!
                    </h2>
                    <h5 className='text-center   section-wide-xs'>
                      Reach out to us with your query or concern
                    </h5>
                    <form>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text' id='basic-addon1'>
                            <i className='fa fa-user'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Name'
                          aria-label='Name'
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text' id='basic-addon1'>
                            <i className='fa fa-phone'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Contact No'
                          aria-label='Name'
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text' id='basic-addon1'>
                            <i className='fa fa-envelope'></i>
                          </span>
                        </div>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Email Address'
                          aria-label='Email Address'
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      <div className='input-group mb-3'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text' id='basic-addon1'>
                            <i className='fa fa-question-circle'></i>
                          </span>
                        </div>
                        <textarea
                          type='text'
                          className='form-control'
                          placeholder='How can We help?'
                          aria-label='Email Address'
                          aria-describedby='basic-addon1'
                        />
                      </div>
                      <div className='text-center'>
                        <button
                          className='btn btn-success'
                          style={{
                            background: '#f36',
                            border: 'none',
                            borderRadius: '3px',
                            padding: '12px 17px',
                            color: '#fff',
                            display: 'inline-block',
                          }}
                        >
                          {' '}
                          Lets Get in touch{' '}
                        </button>
                        <p
                          className='pt-3'
                          style={{ fontSize: '18px', color: '#333' }}
                        >
                          Looking for help contact us by sending email at
                          info@itfixed247.com
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col-md-3 p-3'>
                  <h3> Contact Us </h3>
                  <p>
                    <strong> Address </strong>
                    <address>
                      17153 bear Valley rd STE1 Hesperia CA 92345 USA
                    </address>
                  </p>
                  <p>
                    <strong> Email </strong>
                    <address>info@itfixed247.com</address>
                  </p>
                  <p>
                    <strong> Phone </strong>
                    <address>909-407-4131</address>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactUs;
