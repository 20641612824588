import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import HomepageBanner from '../components/HomepageBanner';
import Footer from '../components/Footer';
import { ServiceCard } from '../components/ServiceCard';

class Homepage extends Component {
  state = { services: null };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { services } = this.props.services;
    this.setState({ services });
  }

  render() {
    if (this.state.services) {
      return (
        <div>
          <HomepageBanner />
          <div className="p-4">
            <h2 className="mb-4 text-center">Featured Services</h2>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                maxWidth: '100%',
              }}
            >
              {this.state.services.map((service, index) => {
                if (index % 5 === 0) {
                  return <div className="break" key={index}></div>;
                } else {
                  return <ServiceCard service={service} index={index} />;
                }
              })}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center mb-2">
                  <h2>ITES Services</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card-deck mb-3 text-center">
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h4 className="my-0 font-weight-normal">Gold</h4>
                    </div>
                    <div className="card-body">
                      <h1 className="card-title pricing-card-title">$199</h1>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>10 users included</li>
                        <li>2 GB of storage</li>
                        <li>Email support</li>
                        <li>Help center access</li>
                      </ul>
                      <a
                        href="/contact-us"
                        type="button"
                        className="button-34 mb-2"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h4 className="my-0 font-weight-normal">Platinum</h4>
                    </div>
                    <div className="card-body">
                      <h1 className="card-title pricing-card-title">$299</h1>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>20 users included</li>
                        <li>10 GB of storage</li>
                        <li>Priority email support</li>
                        <li>Help center access</li>
                      </ul>
                      <a
                        href="/contact-us"
                        type="button"
                        className="button-34 mb-2"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h4 className="my-0 font-weight-normal">Custom</h4>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title pricing-card-title">
                        Contact Us for Price
                      </h3>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>Unlimited users included</li>
                        <li>Unlimited storage</li>
                        <li>Phone and email support</li>
                        <li>Help center access</li>
                      </ul>
                      <a
                        href="/contact-us"
                        type="button"
                        className="button-34 mb-2"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return (
        <div>
          <h3> LOADING.. </h3>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  services: state.services,
});

export default connect(mapStateToProps, {})(Homepage);
