import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div
          className='p-5'
          style={{ background: '#fff', borderTop: '1px solid #f1f1f1' }}
        >
          <p className='text-center '>
            We're committed to your complete satisfaction with our service. If
            you're not 100% happy, we'll do whatever it takes to make it right.
            <br />
            If you need any help or have any question?
          </p>
          <div className='text-center' style={{ marginBottom: '20px' }}>
            <Link
              to='/contact-us'
              style={{
                background: 'rgb(31, 130, 197)',
                color: 'rgb(255, 255, 255)',
                border: '1px solid rgb(31, 130, 197)',
                fontWeight: 'bold',
                padding: '10px',
              }}
            >
              CONTACT US
            </Link>
          </div>
        </div>
        <div style={{ background: '#f3f3f3', borderTop: '1px solid #E7E7E7' }}>
          <div className='footer-navigation'>
            <ul style={{ margin: 0, padding: 0 }}>
              <li>
                <Link to='/cancellation-policy'> Cancellation Policy </Link>
              </li>
              <li>
                <Link to='/terms-of-service'> Terms of Service </Link>
              </li>
              <li>
                <Link to='/privacy-policy'> Privacy Policy </Link>
              </li>
              <li>
                <Link to='/refund-policy'> Refund Policy </Link>
              </li>
            </ul>
            <p className='p-3'>
              ITFixed247 provides Help, Installation, Setup and Repair
              <br />© 2020 ITFixed247. All Rights Reserved.
            </p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Footer;
