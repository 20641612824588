import React, { Component } from 'react';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { CartComponent } from '../components/CartComponent';

class Cart extends Component {
  render() {
    return (
      <div style={{ background: '#f1f1f8' }}>
        <div className='p-3 '>
          <h1> Cart </h1>
          <CartComponent />
          <div
            style={{
              textAlign: 'right',
              background: '#fff',
              marginTop: '10px',
            }}
          >
            <img
              src='/images/payments.jpg'
              alt='Payment'
              style={{ height: '75px' }}
            />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Cart;
