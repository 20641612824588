import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class PageBanner extends Component {
  render() {
    return (
      <Fragment>
        <div
          className='page-banner'
          style={{
            background: `url(/images/${this.props.background})`,
            padding: '180px 0px',
            backgroundPosition: 'top',
          }}
        >
          <h3
            className='text-center'
            style={{
              color: '#fff',
              transform: 'matrix(1, 0, 0, 1, 0, 0)',
              opacity: 1,
              fontSize: '44px',
            }}
          >
            {' '}
            {this.props.title}{' '}
          </h3>
        </div>
        <div aria-label='breadcrumb'>
          <ul className='breadcrumb breadcrumb-arrow'>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <span className='active last aboutus'> {this.props.title}</span>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}
PageBanner.defaultProps = {
  title: 'About Us',
  background: 'page-banner.jpg',
};

export default PageBanner;
