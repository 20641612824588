import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';

export class CloudComputing extends Component {
  render() {
    return (
      <div>
        <PageBanner title="Cloud Computing" background="page-banner.jpg" />
        <div className="p-3">
          <p>
            <strong> Cloud computing </strong> is the delivery of on-demand
            computing services that includes servers, storage, databases,
            networking, software, analytics, and to offer faster innovation,
            flexible resources, and economies of scale intelligence over the
            Internet (The Cloud). Cloud computing is a significant shift from
            the conventional way businesses think about IT resources.
          </p>
          <p>
            Here are some of the common reasons why organisations are turning to
            cloud computing services:
          </p>
          <div className="row">
            <div className="col-md-4">
              <h5> COST </h5>
              <p>
                Cloud computing removes the capital expense of purchasing
                hardware and software and setting up and running on-site data
                centres, the racks of servers, the round-the-clock electricity
                for power and cooling, the IT experts for managing the
                infrastructure. It adds up fast.
              </p>
            </div>
            <div className="col-md-4">
              <h5> SPEED </h5>
              <p>
                {' '}
                Most cloud computing services are bestowed self service and on
                call, so even extensive amounts of computing resources can be
                provisioned in minutes, generally with just a few mouse clicks,
                permitting businesses a lot of variability and taking the
                pressure off capacity planning.{' '}
              </p>
            </div>
            <div className="col-md-4">
              <h5> PRODUCTIVITY </h5>
              <p>
                {' '}
                On-site data centres generally need a lot of racking and
                stacking, hardware setup, software patching, and other
                time-consuming IT management duties. Cloud computing eliminates
                the requirement for many of these tasks, therefore, IT teams can
                spend time on accomplishing more essential business objectives.
              </p>
            </div>
            <div className="col-md-4">
              <h5> RELIABILITY </h5>
              <p>
                {' '}
                Cloud computing assembles data backup, disaster recovery and
                business continuity effortless and economical because data can
                be mirrored at numerous redundant sites on the cloud provider’s
                network.{' '}
              </p>
            </div>
            <div className="col-md-4">
              <h5> SECURITY </h5>
              <p>
                {' '}
                Many cloud providers provide a wide spectrum of policies,
                technologies and controls that build up your security posture
                comprehensively, assisting to protect your data, apps and
                infrastructure from potential threats.{' '}
              </p>
            </div>
            <div className="col-md-4">
              <h5> GLOBAL SCALE </h5>
              <p>
                {' '}
                The advantages of cloud computing services comprise the
                potential to scale elastically, that means conveying the
                abundance of IT resources. For example, more or less computing
                power, storage, bandwidth, right when it is required and from
                the right geographic location.{' '}
              </p>
            </div>
            <div className="col-md-4">
              <h5> PERFORMANCE </h5>
              <p>
                The significant cloud computing services run on a worldwide
                network of secure data centres, which are systematically
                upgraded to the latest generation of quick and systematic
                computing hardware. This provides various advantages over a
                single corporate data centre, encompassing reduced network
                latency for applications and greater economies of scale.
              </p>
            </div>
          </div>
          <hr />
          <h5> TYPES OF CLOUD COMPUTING </h5>
          <p>
            {' '}
            There are three different ways to deploy cloud services i.e. on a
            public cloud, private cloud or hybrid cloud.{' '}
          </p>
          <div className="row">
            <div className="col-md-4">
              <h5> PUBLIC CLOUD </h5>
              <p>
                Public clouds are hold and handled by a third-party cloud
                service providers, which supply their computing resources like
                servers and storage over the Internet. With a public cloud, all
                hardware, software and other supporting infrastructure is owned
                and controlled by the cloud provider.{' '}
              </p>
            </div>
            <div className="col-md-4">
              <h5> PRIVATE CLOUD </h5>
              <p>
                A private cloud means to cloud computing resources used
                completely by a single business or organisation. It can be
                objectively located on the company’s on-site data centre. Some
                companies also pay third-party service providers to organize
                their private cloud. A private cloud is one in which the
                services and infrastructure are preserved on a private network.
              </p>
            </div>
            <div className="col-md-4">
              <h5> HYBRID CLOUD </h5>
              <p>
                {' '}
                Hybrid clouds incorporate public and private clouds, constrained
                together by technology that enables data and applications to be
                shared between them. By enabling data and applications to move
                between private and public clouds, a hybrid cloud offers your
                business prominent flexibility, more deployment options and
                helps enhance your existing infrastructure, security and
                compliance.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center mb-2">
                <h2>Our Pricing</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card-deck mb-3 text-center">
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">Gold</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">$199</h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>10 users included</li>
                      <li>2 GB of storage</li>
                      <li>Email support</li>
                      <li>Help center access</li>
                    </ul>
                    <a
                      href="/contact-us"
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">Platinum</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">$299</h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>20 users included</li>
                      <li>10 GB of storage</li>
                      <li>Priority email support</li>
                      <li>Help center access</li>
                    </ul>
                    <a
                      href="/contact-us"
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">Custom</h4>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title pricing-card-title">
                      Contact Us for Price
                    </h3>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>Unlimited users included</li>
                      <li>Unlimited storage</li>
                      <li>Phone and email support</li>
                      <li>Help center access</li>
                    </ul>
                    <a
                      href="/contact-us"
                      type="button"
                      className="btn btn-lg btn-block btn-primary"
                    >
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CloudComputing;
