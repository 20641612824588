import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';

export class RefundPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <PageBanner title='Refund Policy' background='page-banner.jpg' />
        <div className='p-3'>
          <h3> Refund Policy </h3>
          <p>
            All services offered by ITFixed247 are invoiced as per-incident
            charges. ITFixed247 will ensure a resolution else money back within
            a stipulated time of 30 days from date of service rendered. Date of
            service rendered is the date when customer first speaks with a
            technician for receiving support for the original incident. If
            incident reoccurs within the 30 days of initial service period and
            ITFixed247 is unable to completely fix the incident, then customer
            will receive a full refund. Customer may not receive a refund if
            there is another incident that is not of the same origin as the
            original incident within initial 30 day period.
          </p>
          <p>
            ITFixed247 issues refund for all problems that turns to be
            out-of-scope, and problems that cannot be fixed over the phone or
            remote. If customer is not satisfied with the services, or if the
            issue was not resolved then customer can ask for a refund within 30
            days time of when incident was brought to the attention of
            ITFixed247. ITFixed247 offers 24*7 support and customers can call us
            anytime throughout the day to get the issue resolved. If the same
            initial problem reoccurs but after 30 days from the date of
            activation, and if problem could not be fixed this time, then
            ITFixed247 doesn´t hold any commitment of refunding the money. Money
            back policy for original problem resides only in the first 30 days
            of activation.
          </p>
          <p>
            We offer 30 Money Back Guarantee on almost every product we offer.
            Please call customer service at 1-855-268-5406, in advance to
            register your Refund Request within 30 days of purchase date."
          </p>
          <p>
            All refunds will be provided as a credit to the credit card used at
            the time of purchase within five (5) business days upon receipt of
            the Refund Request."
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default RefundPolicy;
