import React from 'react';
import { useCart } from '../hooks/useCart';

export const CartTotalComponent = () => {
  const { cart, addToCart, getCart, clearCart, removeItem, checkItemInCart } =
    useCart();
  return (
    <div style={{ background: '#fff', padding: '20px' }}>
      <div>
        <h5 style={{ fontSize: 15, fontWeight: 600 }}> Total Amount to Pay</h5>
        <h3 style={{ fontSize: 25, fontWeight: 600 }}>
          ${cart.reduce((acc, item) => acc + item.price, 0)}
        </h3>
      </div>
    </div>
  );
};
