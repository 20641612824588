import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {
  state = { active: false };
  render() {
    return (
      <Fragment>
        <nav id="sidebar" className={this.state.active ? 'active' : ''}>
          <div className="custom-menu">
            <button
              type="button"
              id="sidebarCollapse"
              className="btn btn-primary"
              onClick={() => {
                this.setState({ active: !this.state.active });
              }}
            >
              <i className="fa fa-bars" />
              <span className="sr-only">Toggle Menu</span>
            </button>
          </div>
          <div className="p-4 pt-5">
            <div className="bg-white " style={{ borderRadius: 5 }}>
              <h1>
                <Link to="/" className="logo">
                  <img
                    src="/images/logo.png"
                    alt="IT FIxed 247"
                    style={{ width: '100%' }}
                  />
                </Link>
              </h1>
            </div>
            <ul className="list-unstyled components mb-5">
              <li>
                <Link to="/">
                  {' '}
                  <i className="fa fa-home"></i> Home{' '}
                </Link>
              </li>

              <li>
                <a
                  href="#printersolution"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-print"></i> Printer Solutions
                </a>
                <ul className="collapse list-unstyled" id="printersolution">
                  <li>
                    <Link to="/services/printer-setup">Printer Setup </Link>
                  </li>
                  <li>
                    <Link to="/services/printer-installation">
                      Printer Installation
                    </Link>
                  </li>

                  <li>
                    <Link to="/services/printer-troubleshooting">
                      Printer Troubleshooting
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#pageSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-wifi"></i> Router Solutions
                </a>
                <ul className="collapse list-unstyled" id="pageSubmenu">
                  <li>
                    <Link to="/services/wifi-and-network-connectivity">
                      WiFi and Network Connectivity{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/set-up-a-new-wifi-connection">
                      New WiFi Connection Setup{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/extend-a-wifi-signal">
                      WiFi Signal Extension
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#antivirus"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-shield"></i> Antivirus Solutions
                </a>
                <ul className="collapse list-unstyled" id="antivirus">
                  <li>
                    <Link to="/services/pc-optimization">PC Optimization </Link>
                  </li>
                  <li>
                    <Link to="/services/virus-removal-cleanup">
                      Virus Removal & Cleanup
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#ites"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-cloud"></i> ITES Services
                </a>
                <ul className="collapse list-unstyled" id="ites">
                  <li>
                    <Link to="/network-security">Network Security. </Link>
                  </li>
                  <li>
                    <Link to="/cloud-computing">Cloud Computing.</Link>
                  </li>
                  <li>
                    <Link to="/managed-services">Managed Services.</Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#computer"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-desktop"></i> Computer Solutions
                </a>
                <ul className="collapse list-unstyled" id="computer">
                  <li>
                    <Link to="/services/new-computer-setup">
                      New Computer Setup
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/computer-repair">Computer Repair </Link>
                  </li>
                  <li>
                    <Link to="/services/computer-tuneup">Computer Tune-up</Link>
                  </li>
                  <li>
                    <Link to="/services/data-backup-or-transfer">
                      Data Back-up or Transfer
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/services/operating-system-services">
                  <i className="fa fa-wrench"></i> Operating System
                </Link>
              </li>
              <li>
                <Link to="/services/browser-setup">
                  <i className="fa fa-globe"></i> Browsers Setup
                </Link>
              </li>
              <li>
                <Link to="/services/application-software">
                  <i className="fa fa-briefcase"></i> Application Software
                </Link>
              </li>

              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us"> Contact Us </Link>
              </li>
            </ul>

            <div className="footer">
              <p>Copyright © 2020 IT Fixed 247</p>
            </div>
          </div>
        </nav>
      </Fragment>
    );
  }
}

export default Navbar;
