import React, { Component } from 'react';
import { connect } from 'react-redux';
import ServiceBannerSingle from '../../components/ServiceBannerSingle';
import Footer from '../../components/Footer';

class SingleService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: null,
      serviceFound: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillMount() {
    const { service } = this.props.match.params;
    console.log(service);
    const { services } = this.props.services;
    const single_service = services.find((item) => {
      return item.slug === service;
    });

    if (single_service) {
      this.setState({ service: single_service });
    } else {
      this.setState({ serviceFound: false, service: null });
      window.location = '/404';
    }
  }
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.match.params.service !== prevProps.match.params.service) {
      const { services } = this.props.services;
      const single_service = services.find((item) => {
        return item.slug === this.props.match.params.service;
      });

      if (single_service) {
        this.setState({ service: single_service });
        window.scrollTo(0, 0);
      } else {
        this.setState({ serviceFound: false, service: null });
        window.location = '/404';
      }
    }
  }
  render() {
    if (!this.state.serviceFound) {
      window.location = '/404';
    }
    //console.log(this.state.service);
    if (this.state.service) {
      const { service } = this.state;
      return (
        <div>
          <div
            style={{
              backgroundRepeat: 'no-repeat',
              height: '80vh',
              backgroundSize: 'cover',
              backgroundImage: `url(${service.cover})`,
            }}
          >
            <ServiceBannerSingle
              service={service}
              services={this.props.services}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div
              className='p-3'
              style={{ flex: 1, borderRight: '1px solid #E7E7E7' }}
            >
              <h3> STARTS AT ONLY ${service.price} </h3>
              <p>{service.descrition}</p>
              <h3>Services Include:</h3>
              <p>
                <ul>
                  {service.services_included.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </p>
            </div>
            <div className='p-3' style={{ flex: 1 }}>
              <h3> FAQ's </h3>
              {service.faqs.map((faq, index) => {
                return (
                  <p key={index}>
                    <strong>{faq.question}</strong> <br />
                    {faq.answer}
                  </p>
                );
              })}
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return (
        <div>
          <h3> Loading.. </h3>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  services: state.services,
});

export default connect(mapStateToProps, {})(SingleService);
