import React, { Component, Fragment } from 'react';
import { CartButton } from './CartButton';

class ServiceBannerSingle extends Component {
  render() {
    return (
      <Fragment>
        <div
          style={{
            background: 'rgba(255,255,255,0.6)',
            padding: '10px 20px',
          }}
          id='servicetitle'
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ color: '#263746', flex: 1 }}>
              <h1 style={{ color: '#263746' }}>{this.props.service.title}</h1>
              {/* {this.props.service.slug === 'printer-setup' && (
                <h3> Need Help? Call 1-800-786-2165 </h3>
              )} */}
            </div>

            <div style={{ flex: 1, textAlign: 'right' }}>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  flexDirection: 'column  ',
                }}
              >
                <div style={{ display: 'flex', float: 'right' }}>
                  <p style={{ paddingTop: '7px' }}>STARTS AT ONLY </p>
                  <h2
                    style={{
                      padding: '0px 5px',
                      fontSize: '36px',
                      lineHeight: 1,
                      letterSpacing: '-1px',
                      color: '#263746',
                      fontWeight: 'bold',
                    }}
                  >
                    ${this.props.service.price}
                  </h2>
                </div>
                <CartButton
                  service={this.props.service}
                  services={this.props.services?.services}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ServiceBannerSingle;
