import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../hooks/useCart';

export const CartComponent = () => {
  const { cart, addToCart, getCart, clearCart, removeItem, checkItemInCart } =
    useCart();
  return (
    <div
      className="text-center"
      style={{ background: '#fff', paddingTop: '50px' }}
    >
      {cart && cart.length > 0 ? (
        <div>
          <div>
            {cart.map((item, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px',
                  borderBottom: '1px solid #f1f1f1',
                }}
              >
                <h4> {item.title} </h4>
                <h4 style={{ fontWeight: 600 }}> ${item.price} </h4>
                <button
                  className="button-34 btn-danger"
                  onClick={() => removeItem(index)}
                >
                  {' '}
                  Remove{' '}
                </button>
              </div>
            ))}
          </div>
          <div style={{ padding: '20px' }}>
            <h3 style={{ fontWeight: 500 }}>
              {' '}
              Total: ${cart.reduce((acc, item) => acc + item.price, 0)}{' '}
            </h3>
            <Link to="/checkout" className=" btn-success button-34">
              {' '}
              Checkout{' '}
            </Link>
            <button
              className="button-34 btn-danger ml-2"
              onClick={() => clearCart()}
            >
              {' '}
              Clear Cart{' '}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              fontSize: '52px',
              background: '#f1f1f8',
              borderRadius: '50%',
              width: '100px',
              height: '100px',
              margin: '0px auto',
            }}
          >
            <i className="fa fa-shopping-basket"></i>
          </div>
          <h3> Your cart is empty. </h3>
          <p> Click the button below to add a new service!</p>
          <div style={{ padding: '50px' }}>
            <Link to="/" className="btn btn-success">
              {' '}
              Add A Service{' '}
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
