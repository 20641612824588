import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';

export class CancellationPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <PageBanner title='Cancellation Policy' background='page-banner.jpg' />
        <div className='p-3'>
          <p>
            We understand that schedules can change on short notice as do our
            customers' needs. We try to be as flexible as possible, but this can
            cause our technicians to lose valuable jobs. We often pay our
            technicians for appointments that are cancelled or rescheduled with
            short notice, so we appreciate your understanding. Fees apply to
            in-home services only.
          </p>
          <p>
            {' '}
            <strong> Cancellation </strong>{' '}
          </p>
          <p>
            {' '}
            You may cancel a confirmed appointment up to 24 hours prior to the
            appointment time at no charge.
          </p>
          <p>
            A $25 cancellation fee will be applied to cancellations within 24
            hours of a confirmed appointment.
          </p>
          <p>
            If you fail to cancel at least 3 hours prior to the confirmed
            appointment time, you will be charged in full for your scheduled
            service.
          </p>
          <p>
            {' '}
            <strong> Rescheduling</strong>{' '}
          </p>
          <p>
            You may reschedule a confirmed appointment up to 3 hours prior to
            the appointment time at no charge.
          </p>
          <p>
            A $25 service fee will be applied to appointments rescheduled within
            3 hours of the appointment time.
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CancellationPolicy;
