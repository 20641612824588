import React, { useEffect, useState } from 'react';
import { useCart } from '../hooks/useCart';
import { Link } from 'react-router-dom';

export const CartButton = ({ service, services }) => {
  console.log('services', services);
  const index = services?.findIndex((item) => item.id === service.id);
  const [inCart, setInCart] = useState(false);
  const { cart, addToCart, getCart, clearCart, removeItem, checkItemInCart } =
    useCart();
  const handleCart = () => {
    // findIndex from serives array of service

    addToCart({ ...service, id: index });
  };
  console.log('Cart', cart);
  useEffect(() => {
    if (cart) {
      setInCart(checkItemInCart(index));
    }
  }, [cart]);
  return (
    <>
      {inCart ? (
        <>
          {' '}
          <button
            className='btn btn-sm btn-danger '
            onClick={() => removeItem(index)}
          >
            {' '}
            Remove from Cart{' '}
          </button>
          <Link to='/cart' className='btn btn-sm btn-success mb-2'>
            {' '}
            Go to Cart{' '}
          </Link>{' '}
        </>
      ) : (
        <button
          style={{
            background: '#1f82c5',
            color: '#fff',
            border: '1px solid #1f82c5',
            fontWeight: 'bold',
            padding: '10px 10px',
          }}
          onClick={() => handleCart()}
        >
          BOOK NOW
        </button>
      )}
    </>
  );
};
