import React, { Component } from 'react';
import fire from '../../firebase';
import Footer from '../../components/Footer';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.signup = this.signup.bind(this);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  login(e) {
    e.preventDefault();
    fire
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  signup(e) {
    e.preventDefault();
    fire
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {})
      .then((u) => {
        console.log(u);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <div>
        <div
          className='p-3 '
          style={{
            width: '50%',
            margin: '20px auto',
            border: '1px solid #f1f1f1',
            borderRadius: '5px',
          }}
        >
          <h1> Login </h1>
          <div className='row'>
            <div className='col-md-6'>
              <form>
                <div className='form-group'>
                  <label for='exampleInputEmail1'>Email address</label>
                  <input
                    value={this.state.email}
                    onChange={this.handleChange}
                    type='email'
                    name='email'
                    className='form-control'
                    id='exampleInputEmail1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email'
                  />
                  <small id='emailHelp' className='form-text text-muted'>
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className='form-group'>
                  <label for='exampleInputPassword1'>Password</label>
                  <input
                    value={this.state.password}
                    onChange={this.handleChange}
                    type='password'
                    name='password'
                    className='form-control'
                    id='exampleInputPassword1'
                    placeholder='Password'
                  />
                </div>
                <button
                  type='submit'
                  onClick={this.login}
                  className='btn btn-primary'
                >
                  Login
                </button>
                <button
                  onClick={this.signup}
                  style={{ marginLeft: '25px' }}
                  className='btn btn-success'
                >
                  Signup
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Login;
