import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const config = {
  apiKey: 'AIzaSyBqYDv_9dEhlAFPA2YH_WaZFKCAI9ntkmU',
  authDomain: 'itfixed247-e7d0b.firebaseapp.com',
  databaseURL: 'https://itfixed247-e7d0b.firebaseio.com',
  projectId: 'itfixed247-e7d0b',
  storageBucket: 'itfixed247-e7d0b.appspot.com',
  messagingSenderId: '431275124386',
  appId: '1:431275124386:web:1c0456d4db2c2b921319fb',
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
