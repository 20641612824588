import React, { Component } from 'react';
import PageBanner from '../components/PageBanner';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

export class Terms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <PageBanner title='Terms of Services' background='page-banner.jpg' />
        <div className='p-3'>
          <p>
            This document mentions detailed Terms & Conditions which makes the
            base of commitment between ITFixed247 ("we", "our") and
            you("visitor", "customer"). You are requested to read this document
            to have a better understanding of our plans and processes. Your
            registration with services of ITFixed247 will be done only after you
            agree to all these terms and conditions.
          </p>
          <h3> Support Process</h3>
          <p>
            ITFixed247 provides dual method of support via phone or via remote
            control session over the internet. If you want to stay on the phone
            and follow verbal instruction by our tech support officer then you
            can ask for staying over the phone, else you can grant permission to
            us to get connected to your computer and work on it in front of you.
            Remote session control which we establish can only be done once you
            agree to it and allow for the same. Our remote session process and
            technicalities of the remote access tool doesn't permit us to get
            connected to your computer without your permission.
          </p>
          <p>
            We will only take control of the computer registered with us and we
            will not be responsible for other computers at your place.
            ITFixed247 will take every possible, genuine, and logical effort to
            fix your computer problem or to suggest you the next course of steps
            towards resolution. ITFixed247 will work on your computer over
            remote session in front of you and will keep on educating you about
            the steps being performed, and will confirm the resolution at the
            closure of remote session or that particular call. ITFixed247 under
            no circumstances will be responsible for kind of data loss if
            happens on your computer.
          </p>
          <p>
            You should not do any kind of major troubleshooting or changes on
            your computer without informing us till the time your computer is in
            warranty with us. We will not be responsible for any kind of
            problems that may occur while you make changes, installations,
            updates, etc without informing ITFixed247.
          </p>
          <p>
            <strong> Elimination from the Services </strong> Every plan which
            ITFixed247 offers has a validity period, which commences from the
            date of registration. ITFixed247 will not provide support on to your
            computer after the validity of the plan has expired. We will not
            provide support if you make some modifications on your computer
            which were incompatible with the system, or if your computer system
            has suffered an electric shock or physical damage. In such cases we
            will not be able to help you on the broken system but will only be
            in a position to continue with support once you get the physical
            damage fixed or replace it with a new computer.
          </p>
          <p>
            <Link to='/refund-policy'>
              <strong> Refund Policy Click to read </strong>
            </Link>
          </p>
          <p>
            <strong> Usage Policy </strong> ITFixed247 respects and values your
            relation with us and hopes that will make a fair use of our
            services. We will do our best to always be up to your expectations
            and help you with problems that you might face on your computer. You
            must use our services only for the computers which you have
            registered with us and not for unregistered computers. You should
            not use our service for others like your relatives and friends.
            ITFixed247 has all the rights to bar your services if we feel that
            your calls to us our intentionally done only for disturbing and
            harassing our staff. You will not be refunded in any of such
            intentional misconducts. You will not use any or all portions of our
            website, will not try to hack our servers, information, email ids or
            do anything which could result in damaging our image.
          </p>
          <p>
            During the course troubleshooting we might install third party
            software/tools to fix your problem. By doing this we are just fixing
            your issue and we in no way are endorsing or marketing those
            products. If ITFixed247 will market any product then it will be a
            genuine full version product and you will be getting a separate
            invoice for the same.
          </p>
          <p>
            ITFixed247 also promises not to share your personal information like
            name, contact number, email id, or address with anybody else. Your
            credit card information will nowhere be saved by us once the initial
            registration goes through. For any further transactions for renewal,
            upgrade of services we would need to ask for card details again.
          </p>
          <p>
            <strong> Limitation to act </strong> You agree that should there be
            any case where ITFixed247 is held accountable for any reason, then
            in no case the liability of ITFixed247 will be greater than the
            amount paid to ITFixed247 for services purchased. We will not be
            responsible for any charge that may incur on your card if it gets
            declined while making the transaction for reasons like "short on
            funds" etc. ITFixed247 can anytime review, modify, or change the
            terms and conditions as appearing and the same will always be
            updated on our website www.ITFixed247.com
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Terms;
