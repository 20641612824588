import React from 'react';
import { CartComponent } from './CartComponent';

export const CheckoutComponent = () => {
  return (
    <div style={{ background: '#f1f1f8' }}>
      <div style={{ background: '#fff', paddingTop: '50px', padding: '10px' }}>
        <form>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              {' '}
              First Name{' '}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              Last Name{' '}
            </label>
            <input type="email" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              Phone{' '}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              Email{' '}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              Address 1{' '}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              Address 2{' '}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              City{' '}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              State{' '}
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>
              Zip{' '}
            </label>
            <input type="text" className="form-control" />
          </div>

          <button className=" button-34"> Submit </button>
        </form>
        {/* <CartComponent /> */}
      </div>
    </div>
  );
};
