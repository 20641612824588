import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class HomepageBanner extends Component {
  render() {
    return (
      <div>
        <div className='banner-wrapper'>
          <h3> We Offer instant and quality </h3>
          <h2> Smart and effective solutions for businesses.</h2>
          <p>
            We are fastest growing digital agency that with strong business idea{' '}
            <br />
            and ethics. Check our work with awesome customers.
          </p>
          <div className='banner-button'>
            <Link to='/about-us' className='primary'>
              {' '}
              About Us
            </Link>
            <Link to='/contact-us' className='secondary'>
              {' '}
              Contact Us{' '}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageBanner;
