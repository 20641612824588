import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../hooks/useCart';

export const ServiceCard = ({ service, index }) => {
  const [inCart, setInCart] = useState(false);
  const { cart, addToCart, getCart, clearCart, removeItem, checkItemInCart } =
    useCart();
  const handleCart = () => {
    addToCart({ ...service, id: index });
  };
  console.log('Cart', cart);
  useEffect(() => {
    if (cart) {
      setInCart(checkItemInCart(index));
    }
  }, [cart]);
  return (
    <div
      key={index}
      className="text-center single-service"
      style={{
        flex: 1,
        border: '1px solid #f1f1f1',
        borderRadius: '10px',
        margin: '10px',
        padding: '20px',
      }}
    >
      <h3>
        <Link
          to={`/services/${service.slug}`}
          style={{
            fontSize: '18px',
            fontWeight: '500',
            color: '#222222',
          }}
        >
          {service.title}
        </Link>
      </h3>
      <p> ONLY AT </p>

      <h2 style={{ fontWeight: 600 }}> ${service.price} </h2>
      <div className="border mb-3"></div>
      {inCart ? (
        <>
          {' '}
          <button
            className="button-34   btn-danger mb-2"
            onClick={() => removeItem(index)}
          >
            {' '}
            Remove from Cart{' '}
          </button>
          <br />
          <Link to="/cart" className="button-34  btn-success">
            {' '}
            Go to Cart{' '}
          </Link>{' '}
        </>
      ) : (
        <button className="button-34 mb-2" onClick={() => handleCart()}>
          {' '}
          Add to Cart{' '}
        </button>
      )}
    </div>
  );
};
